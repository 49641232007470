//alert(data.product_form);

function generate_product_form( id, form, productSelection, origID ){
    //console.log(data.ajax_url);
    var formID = id;

    console.log('WILL GENERATE FORM:' + form);
    
    if( productSelection ){
        formID = origID;
    }

    var formEl      = $('#product-' + formID + ' .product-form'),
        spinner     = $('#product-' + formID + ' .cp-spinner'),
        responseDiv = $('#product-' + formID + ' .form-response');

    if( !formEl.hasClass('hide') ){
        formEl.addClass('hide');
    }

    if( spinner.hasClass('hide') ){
        spinner.removeClass('hide');
    }

    responseDiv.addClass('hide');
    responseDiv.removeClass('send-ok');
    responseDiv.html(' ');

    $('#product-' + formID + ' .form-wrap fieldset.dynamic-fieldset').remove();
    //$('#product-' + formID + ' .form-wrap .main-form').remove();
    //$('#product-' + id + ' .form-wrap .form-response').html('');
    //$('#product-' + id + ' .cp-spinner').toggleClass('hide');

    //$('#form-wrap-' + id).toggleClass('hidden');

    return $.ajax({
        type: 'POST',
        url: data.ajax_url,
        data: {
            action: 'get_product_form',
            post_id : id,
            form: form,
            productSelection: productSelection
        },
        dataType: 'json',
        success: function( response ){

//            console.log('success');
//            console.log(response);

            var info_fields        = response.info_fields,
                date_fields        = response.date_fields,
                time_fields        = response.time_fields,
                duration_fields    = response.duration_fields,
                language_fields    = response.language_fields,
                bikerental_fields  = response.bikerental_fields,
                catering_fields    = response.catering_fields,
                location_fields    = response.location_fields,
                default_fields     = response.default_fields,
                notice_fields      = response.notice_fields,
                tos_fields         = response.tos_fields,
                productSelection   = response.productSelection;

//            console.log(date_fields);
//            console.log('PRODUCT SELECT: ' + response.productSelection);

            //$('#product-' + id + ' .form-wrap').append('<form class="product-form" data-id="' + id + '"></form>');

            if( productSelection ){
                id = origID;
            }
            //PRODUCT FORM
            var currentForm = $('#product-' + id + ' .form-wrap .product-form');

            //DEFAULT FIELDS
            if( default_fields ){

                currentForm.append(default_fields.html);
            }

            //PRODUCT INFO
            if( info_fields ){
               // $('#product-' + id + ' .form-wrap .product-form').append(info_fields.html);
            }

            //DATE PICKER
            if( date_fields ){

               // $('#product-' + id + ' .form-wrap .product-form').append(date_fields.html);

                if( date_fields.field_type === 'set_date' ){
                    $('input.datepicker').datepicker({
                        dateFormat: "dd.mm.yy",
                        minDate: new Date()
                    });
                }

                if( date_fields.field_type === 'set_min_max_date' ){
                            
                    if( date_fields.has_exceptions ){

                    $('input.datepicker').datepicker({
                        minDate: new Date(date_fields.minDate),
                        maxDate: new Date(date_fields.maxDate), //25.12.2016 -> newDate(Year, Month(0-11), Day)
                        beforeShowDay: function(date){
                                var string = jQuery.datepicker.formatDate('dd.mm.yy', date);
                                return [ date_fields.exception_dates.indexOf(string) == -1, ' ', date_fields.exception_labels[string] ];
                        }
                    });
                        
                    } else {
            
                     $('input.datepicker').datepicker({
                        minDate: new Date(date_fields.minDate),
                        maxDate: new Date(date_fields.maxDate)
                    });
                                               
                    }
                }

                if( date_fields.field_type === 'set_days_date' ){

                    $('input.datepicker').datepicker({
                        dateFormat: "dd.mm.yy",
                        minDate: new Date(),
                        beforeShowDay: function(date) {
                            var day = date.getDay();

                            //console.log(date_fields.disable_days);
                            //console.log(date_fields.disable_holidays);
                            if($.inArray(day.toString(), date_fields.disable_days) !== -1){
                                return [true, "someothercssclass"]
                            } else {

                                if(date_fields.disable_holidays){
                                    var string = jQuery.datepicker.formatDate('dd.mm.yy', date);
                                    return [ date_fields.disable_holidays.dates.indexOf(string) !== -1, ' ', date_fields.disable_holidays.labels[string] ];
                                }else{

                                    return [false, "somecssclass"]
                                }

                            }


                        }
                    });
                }

                if( date_fields.field_type === 'set_min_max_date_and_days' ){

                    $('input.datepicker').datepicker({
                        dateFormat: "dd.mm.yy",
                        minDate: new Date(date_fields.minDate),
                        maxDate: new Date(date_fields.maxDate),
                        beforeShowDay: function(date) {
                            var day = date.getDay();

                            console.log(date_fields.disable_days);
                            console.log(date_fields.disable_holidays);
                            if($.inArray(day.toString(), date_fields.disable_days) !== -1){
                                return [true, "someothercssclass"]
                            } else {

                                if(date_fields.disable_holidays){
                                    var string = jQuery.datepicker.formatDate('dd.mm.yy', date);
                                    return [ date_fields.disable_holidays.dates.indexOf(string) !== -1, ' ', date_fields.disable_holidays.labels[string] ];
                                }else{

                                    return [false, "somecssclass"]
                                }

                            }


                        }
                    });
                }

                if( date_fields.field_type === 'set_date_range' ) {
                    $( function() {
                        var dateFormat = "mm/dd/yy",
                          from = $( "#from" )
                            .datepicker({
                              //defaultDate: "+1w",
                              dateFormat: "dd.mm.yy",
                              //changeMonth: true,
                              numberOfMonths: 1
                            })
                            .on( "change", function() {
                              to.datepicker( "option", "minDate", getDate( this ) );
                            }),
                          to = $( "#to" ).datepicker({
                            //defaultDate: "+1w",
                            dateFormat: "dd.mm.yy",
                            //changeMonth: true,
                            numberOfMonths: 1
                          })
                          .on( "change", function() {
                            from.datepicker( "option", "maxDate", getDate( this ) );
                          });

                        function getDate( element ) {
                          var date;
                          try {
                            date = $.datepicker.parseDate( 'dd.mm.yy', element.value );
                              console.log(date);
                          } catch( error ) {
                            date = null;
                          }

                          return date;
                        }
                      } );
                }

            }

            //DEFAULT FIELDS
            if( default_fields ){

            }


            formEl.toggleClass('hide');
            spinner.addClass('hide');
            $('.button-application').removeAttr( 'disabled' );

        },
        error: function( response ){
            console.log('error');
            console.log(response);
        }

    });

}

/**
  * SEND PRODUCT FORM
  *
  */
function send_request_form(id, post_data) {
    console.log(post_data);
    //console.log(data.ajax_url);
    //$('#product-' + id + ' .form-wrap fieldset').remove();
    //$('#product-' + id + ' .cp-spinner').toggleClass('hide');
    //$('#form-wrap-' + id).toggleClass('hidden');
    //$('#product-' + id + ' .form-response').toggleClass('hide');
    var responseDiv = $('#product-' + id + ' .form-response'),
        currentForm = $('#product-' + id + ' form');
    responseDiv.removeClass('send-ok');

    var form_type = $('#product-' + id + ' .button-wrap .button.active').data('form');

    if( !form_type ){
        form_type = $('#product-' + id).data('autoload');
    }

    var action = 'send_request_form';

    if(form_type === 'request2'){
        action = 'send_request2_form';
    }

    if(form_type === 'register'){
        action = 'send_register_form';
    }

    if(form_type === 'callback'){
        action = 'send_callback_form';
    }

    if(form_type === 'bikerental'){
        action = 'send_bikerental_form';
    }

    if(form_type === 'feedback'){
        action = 'send_feedback_form';
    }

    $.ajax({
        type: 'POST',
        url: data.ajax_url,
        data: {
            //action: 'send_request_form',
            action : action,
            post_id : id,
            post_data : post_data,
            form_type : form_type
        },
        dataType: 'json',
        success: function( response ){

            $('#product-' + id + ' .cp-spinner').addClass('hide');
            var responseDiv = $('#product-' + id + ' .form-response');
            console.log('success');
            console.log(response)

            //Display Messanges
            if( response.email_1 ){
                responseDiv.addClass('send-ok');
                responseDiv.append('<div class="message success-message">' + response.success + '</div>');
                currentForm.find("input[type=text],input[type=number], textarea").val("");
                $('#product-' + id + ' .form-wrap fieldset').remove();
                $('#product-' + id + ' .product-form').addClass('hide');
            } else {
                responseDiv.append('<div class="message error-message">' + response.error + '</div>');
            }

            responseDiv.removeClass('hide');


        },
        error: function( response ){
            console.log('error');
            console.log(response);
        }

    });
}

function validate_email_address( email, responseDiv, email_input ){
    responseDiv.removeClass('send-ok');
    
    return  $.ajax({
            type: 'POST',
            url: data.ajax_url,
            data: {
                action: 'validate_email_address',
                email : email
            },
            dataType: 'json'

        });

}


var autoload = $('article.page-single').data('autoload');
var pageID   = $('article.page-single').data('page-id');

if (autoload ){
    if( autoload == 'callback' || autoload == 'request2'  ){
        generate_product_form( pageID, autoload, null, null );
    } else {
        generate_product_form( pageID, autoload, null, null ).done(function(r) {
            if (r) {
                $('#product-' + pageID + ' .form-wrap fieldset.dynamic-fieldset').remove();
            }
        });
    }
}


function getUrlParameter(sParam) {
        var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};

var parsedForm = getUrlParameter('form');
console.log('Form: ' + parsedForm);
if( parsedForm ){

    if(parsedForm === 'inquire'){
        parsedForm = 'request';
    }

    if(parsedForm === 'book'){
        parsedForm = 'register';
    }
    
    if(parsedForm === 'general-inquire'){
        parsedForm = 'request2';
    }

    var productID = $('.product-form').data('id'),
        buttonWrap = $('.button-wrap').position();
    $('.button-application[data-form="' + parsedForm + '"]').addClass('active');

    $(window).scrollTop(buttonWrap.top);

    generate_product_form( productID, parsedForm, null, null ).done(function(r) {
        if (r) {
            $('#product-' + pageID + ' .form-wrap fieldset.dynamic-fieldset').remove();
        }
    });
}

//BUTTONS
$( '.produkt' ).on( 'click', '.button-application', function(){

    $('.button-application').removeClass( 'active' );
    $(this).addClass('active');
    $('.button-application').attr( "disabled", "disabled" );

    console.log($( this ).data( 'product-id' ));
    console.log($( this ).data( 'form' ));

    var id   = $( this ).data( 'product-id' ),
        form = $( this ).data( 'form' );

    generate_product_form( id, form, null, null );
});


////BUTTONS
//$( '.produkt' ).on( 'click', '.button-application', function(){
//
//    $('.button-application').removeClass( 'active' );
//    $(this).addClass('active');
//    $('.button-application').attr( "disabled", "disabled" );
//
//    console.log($( this ).data( 'product-id' ));
//    console.log($( this ).data( 'form' ));
//
//    var id   = $( this ).data( 'product-id' ),
//        form = $( this ).data( 'form' );
//
//    generate_product_form( id, form );
//});

//SUBMIT
$( '.produkt, .page-template-page-forms' ).on( 'submit', '.product-form', function(e){
    e.preventDefault();

    //console.log(data.ajax_url);

    var post_data     = $( this ).serializeArray(),
        product_id    = $( this ).data('id'),
        email_input   = $('#product-form-' + product_id + ' input.email'),
        email         = $('#product-form-' + product_id + ' input.email').val(),
        tosCheck      = $('#product-form-' + product_id + ' input.agb-check'),
        honeypot      = $('#product-form-' + product_id + ' input.honeypot').val(),
        validateInput = $('#product-form-' + product_id + ' input.required-field'),
        errors        = [],
        responseDiv   = $('#product-' + product_id + ' .form-response'),
        spinner = $('#product-' + product_id + ' .cp-spinner');

        if( !responseDiv.hasClass('hide') ){
            responseDiv.addClass('hide');
            responseDiv.removeClass('send-ok');
        }

        if( spinner.hasClass('hide') ){
            spinner.removeClass('hide');
        }

    console.log(post_data);
    console.log(product_id);
    console.log(email_input);
    console.log(email);
    //responseDiv.toggleClass('hide');
    //Form Validation
    validateInput.removeClass('not-valid');
    responseDiv.html('');

    errors = [];
    console.log(errors);
    //Check for empty input fields
    validateInput.each(function(e) {
        if( $(this).val().length === 0 ) {
            $(this).addClass('not-valid');
            var name  = $( this ).attr('name'),
                label = '';

                label = $('#product-form-' + product_id + ' label[for="' + name + '"]').text();

            errors.push( '<strong>' + label + '</strong> ' + data.messages.required );
        } else {
//            if( $(this).attr('name') === 'email'){
//                console.log('email address entered');
//                var email_input = $(this),
//                    email = email_input.val();
//                    //validation = validate_email_address( email, responseDiv, email_input );
//
//                //console.log(validation);
//                    if(  validate_email_address( email, responseDiv, email_input ) ){
//                        errors.push(validation);
//                    }
//            }
        }
    }) ;

    if( tosCheck.length > 0 ){
        if (tosCheck.is(":checked")){
            console.log('TOS  CHECKED!');
        } else {
            errors.push('<b>' + data.messages.error + '</b>: ' + data.messages.tos);
        }
    }

    if( honeypot ){
        errors.push('<b>' + data.messages.error + '</b>: ' + data.messages.security);
    }

    //var email = email_input.val();

        console.log(email);
        console.log('BEFORE EMAIL VALIDATION');
    if( email ){
        console.log('START EMAIL VALIDATION');
        validate_email_address( email, responseDiv, email_input ).done(function(r) {
            if (r) {
                console.log('SUCCESS');
              console.log(r);
                // Tell the user they're logged in
              if(r.email === false){
                    errors.push( r.message );
              }


            if(errors.length === 0){
                //Send E-Mails
                console.log('SEND MAIL');
                console.log(product_id);
                console.log(post_data);
                send_request_form(product_id, post_data);

            } else {

                //Display Errors
                $.each( errors, function(index, message){
                    responseDiv.append('<div class="message error-message">' + message + '</div>');
                });

                responseDiv.removeClass('hide');
                spinner.addClass('hide');
                errors = [];
            }


            } else {
                // Tell the user their password was bad
               console.log('no result');

            if(errors.length === 0){
                //Send E-Mails
                send_request_form(product_id, post_data);

            } else {

                //Display Errors
                $.each( errors, function(index, message){
                    responseDiv.append('<div class="message error-message">' + message + '</div>');
                });

                responseDiv.removeClass('hide');
                spinner.addClass('hide');
                errors = [];
            }
            }
        })
        .fail(function(x) {
            // Tell the user something bad happened
              console.log(x);
              console.log('ERROR');

            if(errors.length === 0){
                //Send E-Mails
                send_request_form(product_id, post_data);

            } else {

                //Display Errors
                $.each( errors, function(index, message){
                    responseDiv.append('<div class="message error-message">' + message + '</div>');
                });

                responseDiv.removeClass('hide');
                spinner.addClass('hide');
                errors = [];
            }
        });
	  
	  } else {
	          //Display Errors
            if(errors.length === 0){
                //Send E-Mails
                send_request_form(product_id, post_data);

            } else {

                //Display Errors
                $.each( errors, function(index, message){
                    responseDiv.append('<div class="message error-message">' + message + '</div>');
                });

                responseDiv.removeClass('hide');
                spinner.addClass('hide');
                
                errors = [];
            }
	  }

});
$( '.produkt' ).on( 'change', '.selection-wrap select',function(){

    var option = $(this).find('option:selected'),
        optionVal = option.val(),
        toggleClass = option.data('toggle'),
        toggleField = $(this).parent().next();

    if( toggleClass ){

        toggleField.addClass('is-visible');
        toggleField.toggleClass('hide');

    } else {

        if( toggleField.hasClass('is-visible') ){
            toggleField.removeClass('is-visible');
            toggleField.toggleClass('hide');
        }

    }

});

$( '.page-template-page-forms, .tax-produktgruppe' ).on( 'change', '.selection-wrap select[name=product-category]',function(){

    var option = $(this).find('option:selected'),
        optionVal = option.val(),
        products = $('.selection-wrap select[name=product-title]');

        products.find('option').addClass('hide');
        products.find('.' + optionVal).removeClass('hide');
        products.find('.show-option').removeClass('hide');
        products.val('');

});

$( '.page-template-page-forms, .tax-produktgruppe' ).on( 'change', '.selection-wrap select[name=product-title]',function(){

    var option = $(this).find('option:selected'),
        id = option.data('id'),
        mainForm = $('.main-form'),
        form = $('article').data('autoload'),
        formID = $('article').data('page-id');

    mainForm.remove();

    generate_product_form( id, form, true, formID );

});