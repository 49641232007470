//VARIABLES
var map = null;

$(document).foundation();

$(document).ready(function () {

//SLIDER
    $('.flexslider').flexslider({
        animation: "slide",
        controlNav: false
    });

// TOGGLES
    $('.product.expandable > header').click(function () {
        var product = $(this).parent(),
            mapWrap = product.find('.acf-map');
           //console.log(mapWrap);

        if (product.hasClass('expanded')) {
            product.removeClass('expanded');
        } else {
           //$( '.product.expandable' ).removeClass( 'expanded' );
            product.addClass('expanded');

            if (mapWrap.length > 0) {
                if (mapWrap.hasClass('map-loaded')) {

                } else {
                    mapWrap.addClass('map-loaded');
                    mapWrap.each(function () {
                        // create map
                        map = new_map($(this));
                    });
                }
            }
        }
    });

    $('.detail-box.expandable h3').click(function () {
        var box = $(this).parent();

        if (box.hasClass('expanded')) {
            box.removeClass('expanded');
        } else {
           //$( '.detail-box.expandable' ).removeClass( 'expanded' );
            box.addClass('expanded');
        }
    });

//DROPDOWN MENU (Produktgruppen)    
    $('.menu-groups-dropdown > li > a').click(function (e) {
        e.preventDefault();
        var defaultItem = $(this),
            parentMenu  = defaultItem.parent(),
            subMenu     = defaultItem.next();

        if (!subMenu.hasClass('show-menu')) {
            subMenu.addClass('show-menu');
            parentMenu.addClass('menu-open');
        } else {
            subMenu.removeClass('show-menu');
            parentMenu.removeClass('menu-open');
        }

    });
//STICKY HEADER
    $(window).scroll(function () {
        var scroll   = $(window).scrollTop(),
            topBar   = $('.top-bar'),
            pageMenu = $('.page-menu-header'),
            products = $('section#primary');

        if (scroll >= 100) {
            if (!topBar.hasClass('small-header')) {
                //return;
                topBar.addClass('small-header');
            }
        } else {
            if (topBar.hasClass('small-header')) {
                topBar.removeClass('small-header');
            }
        }

        if (scroll >= 100) {
            if (!pageMenu.hasClass('sticky-menu')) {
                pageMenu.addClass('sticky-menu');
                products.addClass('sticky-menu');

            }
        } else {
            if (pageMenu.hasClass('sticky-menu')) {
                pageMenu.removeClass('sticky-menu');
                products.removeClass('sticky-menu');
            }
        }

        //console.log(scroll);

    });

    

//PRODUCT FILTER

$('.filter-menu .menu-item').click( function(){
    var button  = $(this),
        filter  = button.data('filter'),
        product = $('article.product.' + filter);

    $('.filter-menu .menu-item').removeClass('filtered');
    $('article.product.active-filter').toggleClass('fade');
    $('article.product').removeClass('active-filter');
    button.toggleClass('filtered');
    product.removeClass('expanded');
    //product.toggleClass('fade-out');

    product.addClass('active-filter');
    product.toggleClass('fade');

});

//DATE PICKER
var array = ["19.05.2016","26.08.2016","26.11.2016"]; //Array of restricted Days

//$('input.datepicker').focus(function () {
//
//    var array = $(this).data('exceptions');
//                 console.log(new Date(2016, 10, 25));
//                 console.log(new Date($(this).data('min')));
//});
    $('input.datepicker').datepicker({
        //minDate: new Date($(this).data('min')), //25.11.2016 -> newDate(Year, Month(0-11), Day)
        minDate: function(date){ console.log($(this).data('min'));},
        maxDate: new Date($(this).data('max')), //25.12.2016 -> newDate(Year, Month(0-11), Day)
        beforeShowDay: function(date){
            var string = jQuery.datepicker.formatDate('dd.mm.yy', date);
            return [ array.indexOf(string) == -1 ]
        }
    });

// ADDITIONAL CONTENT - FILL UP COLUMN WITH SPACER ELEMENT
    var colLeft  = $( '.content-col-left' );
    var colRight = $( '.content-col-right' );

    if (colLeft.length > 0 && colRight.length > 0 ){

        var sizeCol1 = colLeft.height();
        var sizeCol2 = colRight.height();

        var elemHeight = sizeCol1 - sizeCol2;

        if( elemHeight < 0 ){
            elemHeight = (elemHeight * -1) - 1;
            colLeft.append('<div class="spacer"></div>');
            colLeft.find('.spacer').css('height',elemHeight + 'px');
        } else {
            elemHeight = elemHeight - 1;
            colRight.find('.spacer').css('height',elemHeight + 'px');           colRight.append('<div class="spacer"></div>');
            colRight.find('.spacer').css('height',elemHeight + 'px');
        }

        //console.log(elemHeight);
    }

//$('.searchterm').on('click', function(){
//   $('.searchwrapper').toggleClass('open');
//    console.log('focused');
//});

$('.sb-icon-search').click(function(){
    $('.sb-search').addClass('sb-search-open');
})
// FOOTER MENU
//var footerHeight = 0;
//$('.menu-footer > li').each( function(){
//   var elHeight = $(this).height();
//    if(elHeight > footerHeight){
//        footerHeight = elHeight;
//
//        $('.menu-footer > li').css('height',footerHeight);
//    }
//});

//// MASONRY - AKTUELLES
var $grid = $('.news-posts').masonry({
  // set itemSelector so .grid-sizer is not used in layout
  itemSelector: '.news-item',
  // use element for option
  columnWidth: '.news-sizer',
  percentPosition: true,
  initLayout: false
});
// bind event
$grid.masonry( 'on', 'layoutComplete', function() {
  console.log('layout is complete');
});
// trigger initial layout
$grid.masonry();

// MASONRY - FEEDBACKS
$('.feedbacks').masonry({
  // set itemSelector so .grid-sizer is not used in layout
  itemSelector: '.feedback-item',
  // use element for option
  columnWidth: '.feedback-sizer',
  percentPosition: true
});

//const newsBricks = Bricks({
//  container: '.news-item'
//});
//const feedbackBricks = Bricks({
//  container: '.feedback-item'
//});

//IMAGE DOWNLOADS
$('.downloadable-item').click( function(){
    $(this).toggleClass('checked');

    var skuArray = [];
    $('.downloadable-item.checked').each( function(){
        var sku = $(this).find('.sku').data('sku');
            skuArray.push(sku);
    });

    var skus = skuArray.toString();

    $('.anfrage input').val(skus);
});

//SLICK SLIDER
$('.responsive-slick-slider').slick({
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});
	
    
// ACF GOOGLE MAPS

/*
    *  new_map
    *
    *  This function will render a Google Map onto the selected jQuery element
    *
    *  @type	function
    *  @date	8/11/2013
    *  @since	4.3.0
    *
    *  @param	$el (jQuery element)
    *  @return	n/a
    */

    function new_map( $el ) {

        // var
        var $markers = $el.find('.marker');

        // vars
        var args = {
            zoom		: 16,
            center		: new google.maps.LatLng(0, 0),
            mapTypeId	: google.maps.MapTypeId.ROADMAP
        };

        // create map
        var map = new google.maps.Map( $el[0], args);

        // add a markers reference
        map.markers = [];

        // add markers
        $markers.each(function(){

            add_marker( $(this), map );

        });

        // center map
        center_map( map );

        // return
        return map;

    }

    /*
    *  add_marker
    *
    *  This function will add a marker to the selected Google Map
    *
    *  @type	function
    *  @date	8/11/2013
    *  @since	4.3.0
    *
    *  @param	$marker (jQuery element)
    *  @param	map (Google Map object)
    *  @return	n/a
    */

    function add_marker( $marker, map ) {

        // var
        var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

        // create marker
        var marker = new google.maps.Marker({
            position	: latlng,
            map			: map
        });

        // add to array
        map.markers.push( marker );

        // if marker contains HTML, add it to an infoWindow
        if( $marker.html() )
        {
            // create info window
            var infowindow = new google.maps.InfoWindow({
                content		: $marker.html()
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function() {

                infowindow.open(map, marker);

            });
        }

    }

    /*
    *  center_map
    *
    *  This function will center the map, showing all markers attached to this map
    *
    *  @type	function
    *  @date	8/11/2013
    *  @since	4.3.0
    *
    *  @param	map (Google Map object)
    *  @return	n/a
    */

    function center_map( map ) {

        // vars
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each( map.markers, function( i, marker ){

            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

            bounds.extend( latlng );

        });

        // only 1 marker?
        if( map.markers.length == 1 )
        {
            // set center of map
            map.setCenter( bounds.getCenter() );
            map.setZoom( 16 );
        }
        else
        {
            // fit to bounds
            map.fitBounds( bounds );
        }

    }

    /*
    *  document ready
    *
    *  This function will render each map when the document is ready (page has loaded)
    *
    *  @type	function
    *  @date	8/11/2013
    *  @since	5.0.0
    *
    *  @param	n/a
    *  @return	n/a
    */
    // global var
    var map = null;

    $(document).ready(function(){

        if( $('body').hasClass('single') || $('body').hasClass('page') ){

            $('.acf-map').each(function(){

                // create map
                map = new_map( $(this) );

            });
        }


    });



//    function mmenu_scroll_to_current_item(){
//
//        var menu   = $('.mm-panel')
//            target = menu.find('.active'),
//            scroll = target.offset().top;
////            console.log('scroll to current -> ' + scroll);
//
//        if( scroll > $(window).height() ){
//
//            menu.animate({
//                scrollTop: scroll - pagePadding,
//            }, 1000, 'easeOutCirc');
//        }
//
//        return false;
//    };



    $("#mobile-menu").mmenu({
               slidingSubmenus: false,
               "extensions": [
                  "pagedim-black"
               ],
               "counters": false,
//               "navbar" :  data.searchform,
               "navbars": [
                  {
                     "position": "top",
                     "content": [
                        data.searchform
                     ]
                  },
                  {
                       "position": "bottom",
                       "content": [
                          data.sociallinks
                       ]
                   }
               ]
            });

    var $menu = $("#mobile-menu"),
        $html = $('html, body'),
        API   = $menu.data( "mmenu" );

    API.setSelected( $menu.find( "li" ).first() );

    var closer = null;

//    $menu.find( 'a' ).on( 'click', function() {
//
//        if( $(this).hasClass( 'mm-next') || $(this).hasClass( 'mm-prev')  || $(this).hasClass( 'mm-title')){
//            return;
//        }
//
//        closer = $(this).attr( "href" );
//
//        if ( closer ) {
//            API.close();
////        console.log(closer);
//            setTimeout( function() {
//                var pagePadding = 55;
//                $html.animate({
//                    scrollTop: $(closer).offset().top - pagePadding,
//                });	
//                closer = null;
//            }, 25 );
//        }
//
//    });

    $(".open-mobile-menu").click(function() {
        API.open();
//        mmenu_scroll_to_current_item();
    });

//    $( '.sub-menu li' ).click( function(){
//        $( '.sub-menu li' ).removeClass( 'current-menu-item' );
//        $( this ).addClass( 'current-menu-item' );
//    });
//
//    $( '.sub-menu li a' ).click( function(){
//        $( '.sub-menu li a' ).parent().removeClass( 'current-menu-item' );
//        $( this ).parent().addClass( 'current-menu-item' );
//    });


    var initPhotoSwipeFromDOM = function(gallerySelector) {

    // parse slide data (url, title, size ...) from DOM elements 
    // (children of gallerySelector)
    var parseThumbnailElements = function(el) {
        var thumbElements = el.childNodes,
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;

        for(var i = 0; i < numNodes; i++) {

            figureEl = thumbElements[i]; // <figure> element

            // include only element nodes 
            if(figureEl.nodeType !== 1) {
                continue;
            }

            linkEl = figureEl.children[0]; // <a> element

            size = linkEl.getAttribute('data-size').split('x');

            // create slide object
            item = {
                src: linkEl.getAttribute('href'),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10)
            };



            if(figureEl.children.length > 1) {
                // <figcaption> content
                item.title = figureEl.children[1].innerHTML; 
            }

            if(linkEl.children.length > 0) {
                // <img> thumbnail element, retrieving thumbnail url
                item.msrc = linkEl.children[0].getAttribute('src');
            } 

            item.el = figureEl; // save link to element for getThumbBoundsFn
            items.push(item);
        }

        return items;
    };

    // find nearest parent element
    var closest = function closest(el, fn) {
        return el && ( fn(el) ? el : closest(el.parentNode, fn) );
    };

    // triggers when user clicks on thumbnail
    var onThumbnailsClick = function(e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : e.returnValue = false;

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function(el) {
            return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
        });

        if(!clickedListItem) {
            return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
            childNodes = clickedListItem.parentNode.childNodes,
            numChildNodes = childNodes.length,
            nodeIndex = 0,
            index;

        for (var i = 0; i < numChildNodes; i++) {
            if(childNodes[i].nodeType !== 1) { 
                continue; 
            }

            if(childNodes[i] === clickedListItem) {
                index = nodeIndex;
                break;
            }
            nodeIndex++;
        }



        if(index >= 0) {
            // open PhotoSwipe if valid index found
            openPhotoSwipe( index, clickedGallery );
        }
        return false;
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    var photoswipeParseHash = function() {
        var hash = window.location.hash.substring(1),
        params = {};

        if(hash.length < 5) {
            return params;
        }

        var vars = hash.split('&');
        for (var i = 0; i < vars.length; i++) {
            if(!vars[i]) {
                continue;
            }
            var pair = vars[i].split('=');  
            if(pair.length < 2) {
                continue;
            }           
            params[pair[0]] = pair[1];
        }

        if(params.gid) {
            params.gid = parseInt(params.gid, 10);
        }

        return params;
    };

    var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
        var pswpElement = document.querySelectorAll('.pswp')[0],
            gallery,
            options,
            items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {

            // define gallery index (for URL)
            galleryUID: galleryElement.getAttribute('data-pswp-uid'),

            getThumbBoundsFn: function(index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect(); 

                return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
            }

        };

        // PhotoSwipe opened from URL
        if(fromURL) {
            if(options.galleryPIDs) {
                // parse real index when custom PIDs are used 
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for(var j = 0; j < items.length; j++) {
                    if(items[j].pid == index) {
                        options.index = j;
                        break;
                    }
                }
            } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
            }
        } else {
            options.index = parseInt(index, 10);
        }

        // exit if index not found
        if( isNaN(options.index) ) {
            return;
        }

        if(disableAnimation) {
            options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
    };

    // loop through all gallery elements and bind events
    var galleryElements = document.querySelectorAll( gallerySelector );

    for(var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i+1);
        galleryElements[i].onclick = onThumbnailsClick;
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    var hashData = photoswipeParseHash();
    if(hashData.pid && hashData.gid) {
        openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
    }
};

// execute above function
initPhotoSwipeFromDOM('.acf-gallery-slider');

});

jQuery(window).on('load', function(){ var $ = jQuery;
                                     
//PRODUCT FILTER
//
//    if( $('.filter-menu').length > 0 ){
//        var defaultFilter = $('.filter-menu .menu-item:first-of-type'),
//            filter = defaultFilter.data('filter');
//             product = $('article.product.' + filter);
//
//            product.addClass('active-filter');
//            product.toggleClass('fade');
//            defaultFilter.toggleClass('filtered');
//            product.removeClass('expanded');
//    }
                                     
    // MASONRY - AKTUELLES
    var $grid = $('.news-posts').masonry({
      // set itemSelector so .grid-sizer is not used in layout
      itemSelector: '.news-item',
      // use element for option
      columnWidth: '.news-sizer',
      percentPosition: true,
      initLayout: false
    });
    // bind event
    $grid.masonry( 'on', 'layoutComplete', function() {
      console.log('layout is complete');
    });
    // trigger initial layout
    $grid.masonry();

    // MASONRY - FEEDBACKS
    $('.feedbacks').masonry({
      // set itemSelector so .grid-sizer is not used in layout
      itemSelector: '.feedback-item',
      // use element for option
      columnWidth: '.feedback-sizer',
      percentPosition: true
    });
});